<template>
    <div class="lottery-time" v-if="show">
        <template v-if="!opening">
            <div class="time">
                <img src="@/assets/images/lightning.png" alt="" v-if="countDown.hours==='00'" class="lightning-icon">
                <span v-else>{{ countDown.hours }}</span>
            </div>
            <div class="split">:</div>
            <div class="time">{{ countDown.minutes }}</div>
            <div class="split">:</div>
            <div class="time">{{ countDown.seconds }}</div>
        </template>
        <template v-else>
            <div class="opening">{{ $t('Opening') }}</div>
        </template>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      item: {
        default: null,
      },
    },
    data() {
      return {
        countDown: {
            hours: '00',
            minutes: '00',
            seconds: '00',
        },
        timer: null,
        curr: null,
        opening: false,
        show: true,
      };
    },
    methods: {
         getRemainingTime(targetTimestamp) {
            const now = new Date().getTime();
            const timeDifference = targetTimestamp - now;

            if (timeDifference < 0) {
                this.show = false;
            } else {
                this.show = true;
            }

            if (timeDifference <= 0) {
                return false;
            }

            const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
            if (hours <= 0 && minutes <= 0 && seconds <= 0) {
                this.opening = true;
            } else {
                this.opening = false;
            }
            // Format the result to be in "HH:MM:SS" format
            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');

            return {
                hours: formattedHours,
                minutes: formattedMinutes,
                seconds: formattedSeconds,
            };
        },

        start () {
            const targetTimestamp = this.curr.end_time*1000;
            const remainingTime = this.getRemainingTime(targetTimestamp);
            const oldItem = this.curr;
            if (!remainingTime || this.opening) {
                this.curr = {
                    ...oldItem,
                    start_time: oldItem.end_time,
                    end_time: oldItem.end_time + (oldItem.end_time - oldItem.start_time),
                    lottery_number: oldItem.lottery_number+1
                }
                localStorage.setItem('lottery_' + this.curr.lottery_type, JSON.stringify(this.curr));
            } else {
                this.countDown = remainingTime;
            }
            this.timer = setTimeout(() => {
                this.start();
            }, this.opening ? 3000 : 1000);
        }
    },
    mounted() {
        this.curr = this.item;
        let localCurr = localStorage.getItem('lottery_' + this.curr.lottery_type);
        if (localCurr) {
            localCurr = JSON.parse(localCurr);
            if (localCurr.end_time > this.curr.end_time) {
                this.curr = localCurr;
            }
        }
        if (this.curr) {
            this.start();
        }
    },
    destroyed() {
        clearTimeout(this.timer);
    }
  };
  </script>

<style scoped lang="less">
.lottery-time {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
}

.time {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: #f5f5f5;
    border-radius: 5px;
    margin: 0 1px;
    font-size: 14px;
    color: #333;
    @media (max-width:768px) {
        width: 20px;
        height: 20px;
        font-size: 12px;
        border-radius: 4px;
    }
}
.split {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    font-size: 18px;
    color: #fff;
    line-height: 1;
    @media (max-width:768px) {
        font-size: 14px;
    }
}

.opening {
    font-size: 16px;
    color: #fff;
    font-weight: bold;
}
.lightning-icon{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
</style>
  